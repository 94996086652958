import React from 'react';
import { LoggedInHeaderItem } from '../../components/user/LoggedInHeaderItem';
import { LoggedOutHeaderItem } from '../../components/user/LoggedOutHeaderItem';
import { ButtonEnum, IUser } from '../../shared/lib/types';

export interface IHeader {
    user?: IUser;
    sticky?: boolean;
    onLoginClick: () => void;
    onLogoutClick: () => void;
    onSignUpClick: () => void;
}

const Header = ({ user, sticky = true, onLoginClick, onLogoutClick, onSignUpClick }: IHeader) => (
    <header className={sticky ? 'header header--sticky' : 'header'}>
        <div className="container container--xxl">
            <div className="header__inner">
                <div className="logo">
                    <a href="/">KS Komplett</a>
                </div>
                <div className="header__center"></div>
                <div className="header__end">
                    {!user && (
                        <div className="header__item">
                            <a href="/" className={ButtonEnum.Primary + ' btn--sm'} onClick={onSignUpClick}>
                                Bestill abonnement
                            </a>
                        </div>
                    )}
                    {user && (
                        <>
                            <div className="header__item">
                                <a href="/kunder" className="header__link first-header__link">
                                    Kunder
                                </a>
                                <span className="icon icon-heart first-header__icon" />
                            </div>
                            <div className="header__item">
                                <a href="/veiledere" className="header__link second-header__link">
                                    Veiledere
                                </a>
                                <span className="icon icon-veiledere-og-maler" />
                            </div>
                            <div className="header__item">
                                <LoggedInHeaderItem user={user} onLogoutClick={onLogoutClick} />
                            </div>
                        </>
                    )}
                    {!user && <LoggedOutHeaderItem onLoginClick={onLoginClick} />}
                </div>
            </div>
        </div>
    </header>
);

export default Header;
