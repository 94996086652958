import React, { Component } from 'react';
import { IUser } from '../../shared/lib/types';

interface IUserProps {
    user: IUser;
    onLogoutClick: () => void;
}

interface IUserState {
    loggedIn: boolean;
    isAdmin: boolean;
}

export class LoggedInHeaderItem extends Component<IUserProps, IUserState> {
    static defaultProps = {
        user: {
            name: 'Default name',
        },
    } as IUserProps;

    public readonly state: Readonly<IUserState> = {
        loggedIn: false,
        isAdmin: false,
    };

    componentDidMount() {
        this.setState({ loggedIn: true });
    }
    render() {
        return (
            <button className="header-user header-user--logged-in">
                <span className="header-user__text">
                    <span className="header-user__text__title">
                        {this.props.user.name}
                    </span>

                    {this.props.user.company && (
                        <span className="header-user__text__sub-title">
                            {this.props.user.company}
                        </span>
                    )}
                </span>

                <span className="icon icon-header-inlogget"></span>
            </button>
        );
    }
}
