import React from 'react';

export const LoggedOutHeaderItem = (props: any) => (
    <button onClick={props.onLoginClick} className="header-user">
        <span className="header-user__text">
            <span className="header-user__text__title">Logg inn</span>
        </span>
        <span className="icon icon-header-logg-inn"></span>
    </button>
);
