import React, { lazy, Suspense, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header, { IHeader } from './components/Header';
import Footer from './components/Footer';

const PrototypeLandingPage = lazy(() => import('./pages/PrototypeLandingPage'));
const StartPage = lazy(() => import('./pages/StartPage'));
const ArticlePage = lazy(() => import('./pages/ArticlePage'));
const FAQPage = lazy(() => import('./pages/FAQPage'));
const VeiledereApp = lazy(() => import('./pages/VeiledereApp'));

const headerProps: IHeader = {
    user: { name: 'Navn Navnesen' },
    onLoginClick: () => alert('Login clicked'),
    onLogoutClick: () => alert('Logout clicked'),
    onSignUpClick: () => alert('Signup clicked'),
};

const Prototype = () => {
    const [basename] = useState<string>('/');
    return (
        <Router basename={basename}>
            <ul className="list--unstyled sr-only--focusable">
                <li>
                    <a href="#main">Hovedinnhold</a>
                </li>
            </ul>
            <Header {...headerProps} />

            <div className="main-wrapper">
                <main id="main">
                    <Suspense
                        fallback={
                            <div className="container">
                                <h1>Vennligst vent...</h1>
                            </div>
                        }
                    >
                        <Routes>
                            <Route
                                path="/"
                                element={<PrototypeLandingPage />}
                            />
                            <Route path="/start-page" element={<StartPage />} />
                            <Route
                                path="/article-page"
                                element={<ArticlePage />}
                            />
                            <Route path="/faq-page" element={<FAQPage />} />
                            <Route path="/*" element={<VeiledereApp />} />
                        </Routes>
                    </Suspense>
                </main>
            </div>
            <Footer />
        </Router>
    );
};

export default Prototype;
