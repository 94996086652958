import React from 'react';
import RNLogo from '../../assets/images/RN-logo.svg';

const Footer = () => (
    <footer className="footer">
        <div className="container container--xxl">
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-6">
                            <img
                                className="footer__logo"
                                src={RNLogo}
                                alt="Regnskap Norge logo"
                            />
                        </div>
                        <div className="col-md-6">
                            <h2>Kontakt oss</h2>
                            <ul className="list--unstyled">
                                <li>
                                    <a href="/">Kundesupport/FAQ</a>
                                </li>
                                <li>
                                    <a href="mailto:post@regnskapnorge.no">
                                        post@regnskapnorge.no
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:23356900">23 35 69 00</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <h2>Snarveier</h2>
                    <ul className="list--unstyled">
                        <li>
                            <a href="/">Om KS Komplett</a>
                        </li>
                        <li>
                            <a href="/">Endringslogg</a>
                        </li>
                        <li>
                            <a href="/">Vilkår</a>
                        </li>
                    </ul>
                </div>
                <div className="page-header page-header--dark">
                    <div className='d-md-flex align-items-center justify-content-center cookie'>
                        Regnskap Norge bruker informasjonskapsler for å gi deg en bedre brukeropplevelse.&nbsp;<span>Les mer om personvern her.</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
